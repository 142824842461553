import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Layout from '../components/layout'
import Head from '../components/head'
import Form from '../components/form'
import Cf7FormWrapper from '../components/cf7-form-wrapper'

const IndexPage = ({ data }) => {
  const image = getImage(data.contentfulContenuDePage.image)
  const text2 = (
    <section className="section">
      <div>{renderRichText(data.contentfulContenuDePage.text2)}</div>
    </section>
  )

  return (
    <Layout>
      <Head
        title="Développeur Gatsby Next.JS | DatoCMS | Contentful | WordPress, site web ultra rapide."
        description="Développeur web freelance à Strasbourg - 06 86 41 10 11 - Création de sites web sur mesure - Votre site internet clé en main, performant, responsive avec Gatsby, Next.js, Contentful ou DatoCMS"
      />
      <h2 className="mb-0 mt-section">
        Je suis Jérôme Dicharry, j'ai 40 ans et je suis développeur freelance
        React JS / Gatsby JS / Next.JS / WordPress dans la magnifique ville de
        Strasbourg
      </h2>
      <section className="section section--small">
        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            <GatsbyImage
              image={image}
              className="avatar"
              alt={data.contentfulContenuDePage.image.title}
            />
          </div>
          <div className="cell large-8">
            <div>{renderRichText(data.contentfulContenuDePage.text)}</div>
            <a href="#contact-form" className="button primary">
              Contactez-moi
            </a>
          </div>
        </div>
      </section>
      <section className="realisations full-width bg-primary">
        <div className="grid-container section">
          <h2 className="text-center">
            Mes dernières réalisations (hors agences)
          </h2>
          <p>
            Je n'ai pas listé ici mes réalisations pour des agence web pour
            raison de confidentialité. Ces projets représentent une grande
            partie (90%) de mes travaux.
          </p>
          <p>
            N'hésitez pas cependant à me contacter concernant ces autres
            projets.
          </p>
          <ul className="grid-x grid-margin-y align-center-middle realisations--container">
            {data.allContentfulRealisation.edges.map((edge, key) => {
              const realisationImage = getImage(edge.node.mockUp)
              return (
                <li
                  className="cell small-12 medium-7 large-12"
                  key={edge.node.url}
                >
                  <div className="realisations--card">
                    <div
                      className={`grid-x align-stretch ${
                        key % 2 === 0 ? 'flex-dir-row-reverse' : ''
                      }`}
                    >
                      <div className="cell large-shrink">
                        <a
                          href={edge.node.url}
                          title={edge.node.title}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="realisations--card__image"
                        >
                          <GatsbyImage
                            image={realisationImage}
                            alt={edge.node.title}
                            loading="lazy"
                            placeholder="tracedSVG"
                            objectFit="cover"
                            objectPosition="center"
                          />
                        </a>
                      </div>
                      <div className="cell large-auto">
                        <div className="realisations--card__content">
                          <a
                            href={edge.node.url}
                            title={edge.node.title}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h3>{edge.node.title}</h3>
                          </a>
                          <div>{renderRichText(edge.node.text)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
      {text2}
      <Cf7FormWrapper url="https://jeromedicharry.com/wp-json/contact-form-7/v1/contact-forms/441/feedback">
        <Form />
      </Cf7FormWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulRealisation {
      edges {
        node {
          title
          url
          text {
            raw
          }
          mockUp {
            title
            gatsbyImageData(width: 500, height: 400)
          }
        }
      }
    }
    contentfulContenuDePage(slug: { eq: "accueil" }) {
      slug
      text {
        raw
      }
      text2 {
        raw
      }
      text3 {
        raw
      }
      image {
        title
        gatsbyImageData
      }
    }
  }
`

export default IndexPage
